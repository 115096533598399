import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { makeStyles, Grid, Typography, Container } from '@material-ui/core'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import currencyFormatter from 'currency-formatter'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import Master from '../components/Master'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Chip from '../components/Chip'
import Button from '../ui/ButtonWhatsApp'
import Breadcrumbs from '../ui/CustomBreadcrumbs'
import Categories from '../components/Categories';

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  icon: {
    paddingRight: '5px'
  },
  textButton: {
    fontSize: '20px',
    paddingTop: '5px'
  },
  images: {
    marginTop: '15px'
  },
  image: {
    width: '100%',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  mainImage: {
    width: '100%',
    borderRadius: '5px',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  breadcrumbs: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '22px',
    textDecoration: 'none',
    '& a': {
      color: 'rgba(0, 0, 0, 0.54)',
      textDecoration: 'none',
    }
  },
  breadcrumb: {
    height: '30px'
  }
});

export default ({ data }) => {

  const classes = useStyles()

  const product = data.product
  const images = product.images;
  const imagesThumbnail = product.imagesThumbnail;

  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const open = index => {
    setPhotoIndex(index)
    setIsOpen(true)
  }

  let categories = null
  if (data.product) {
    if (product.categories.length > 0) {
      categories = (
        <div>
          <div>
            <h3>categorias</h3>
          </div>
          <div className={classes.flex}>
            {product.categories.map(category => (
              <Chip key={category.id} link={`/categorias/${category.slug}`}>{category.title}</Chip>
            ))}
          </div>
        </div>
      )
    }
  }
  let keywords = null
  if (data.product) {
    if (product.keywords.length > 0) {
      keywords = (
        <div>
          <div>
            <h3>nuvem de tags</h3>
          </div>
          <div className={classes.flex}>
            {product.keywords.map(keyword => (
              <Chip key={keyword.id} link={`/tags/${keyword.slug}`}>{keyword.title}</Chip>
            ))}
          </div>
        </div>
      )
    }
  }

  let price = null
  if (data.product) {
    if (process.env.SHOW_PRICE === true) {
      if (product.price > 0) {
        price = (
          <h4>
            {currencyFormatter.format(product.price, { locale: 'pt-BR' })}
          </h4>
        )
      }
    }
  }

  return (
    <Master title={product.title}>
      <GatsbySeo
        title={product.title}
        description={product.description}
        canonical={`${process.env.SITE_URL}/produtos/${product.slug}`}
        noindex={false}
        nofollow={false}
        openGraph={{
          url: `${process.env.SITE_URL}/produtos/${product.slug}`,
          title: product.title,
          description: product.description,
          images: [
            {
              url: product.image,
              width: 700,
              height: 700,
              alt: product.title
            }
          ],
          site_name: `${process.env.SITE_NAME}`,
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />
      <Container maxWidth="md" component="main" className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h1 className={classes.center}>{product.title}</h1>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.center}>
              <Breadcrumbs>
                <Link color="inherit" to='/' className={classes.breadcrumbs}>
                  Início
            </Link>
                <Link color="inherit" to={`/produtos`} className={classes.breadcrumbs}>
                  Produtos
            </Link>
                <span className={classes.breadcrumbs}>{product.title}</span>
              </Breadcrumbs>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Categories align="center" />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <img src={product.imageThumbnail} title={product.title} alt={product.title} className={classes.mainImage} />
            <Grid container spacing={3} className={classes.images}>
              {imagesThumbnail.map((image, index) => (
                <Grid key={index} item xs={4} onClick={() => open(index)}>
                  <img src={image} title={product.title} alt={product.title} className={classes.image} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            {price}
            <div id="product" dangerouslySetInnerHTML={{ __html: product.details }} />
            <p>
              <strong>OUTROS MODELOS, CONSULTAR.</strong>
            </p>
            <p>
              <Button param={product.slug} attribute="produtos"><WhatsAppIcon className={classes.icon} /> <span className={classes.textButton}>Quero saber mais</span></Button>
            </p>
            {categories}
            {keywords}
            <div className={classes.breadcrumb}></div>
          </Grid>
        </Grid>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </Container>
    </Master>
  )
}

export const query = graphql`
  query($slug: String!) {
    product(slug: {eq: $slug}) {
        title
        slug
        description        
        details
        image
        imageThumbnail
        images
        imagesThumbnail
        price
        categories {
          id
          title
          slug
        }
        keywords {
          id
          title
          slug
        }
      }
  }
`